
import Vue from 'vue'
import Form from './components/Form.vue'

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
	},
	components: {
		Form
	}
})

Form
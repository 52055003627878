import { MutationTree, Mutation } from 'vuex';
import { IState, ISubscribeData, IMessage } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const subscribeMutation: MutationHandler<ISubscribeData> = (state, { payload }) => {
	if(payload.successfulCreation) {
		Object.assign(state, {
			subscribed: true, 
			message: payload.message
		});
	} else {
		Object.assign(state.message, payload.message);
	}
}

const setMessageMutation: MutationHandler<IMessage> = (state, { payload }) => {
	Object.assign(state.message, payload);
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	subscribe: subscribeMutation,
	setMessage: setMessageMutation
}

export default mutations

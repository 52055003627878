import axios from 'axios'
import { ISubscribeUser } from '@/store/state';

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}

const stringifyParams = (paramsObject: {}) => {
  let params = [];
  for (const [key, value] of Object.entries(paramsObject)) {
    params.push(`${key}=${value}`)
  }
  return params.join('&')
}

export const subscribe = (subscribeUrl: string, params: ISubscribeUser): Promise<ISubscribeUser> => {
    let url = `${subscribeUrl}?${stringifyParams({
      email: params.email,
    })}`
    return axios.get(url, { headers })
      .then((response:any) => {
          return response.data
      })
      .catch((searchError:any) => {
          console.error({
              searchError
          })
          return {} as ISubscribeUser
      })
}

import Vue from 'vue'
import { subscribe } from '../store/actions'
// Interfaces for component typechecking!
interface FormData {
	email: string
	consent: boolean
}

interface FormMethods {
	subscribe(): void
}

interface FormComputed {
	translations: any
	policyUrl: string
	message: string
	subscribed: boolean
}

interface FormProps {

}

export default Vue.extend<FormData, FormMethods, FormComputed, FormProps>({
	name: 'Form',
	data() {
		return {
			email: '',
			consent: false
		}
	},
	computed: {
		translations() {
			return this.$store.state.translations
		},
		policyUrl() {
			return this.$store.state.policyUrl
		},
		message() {
			return this.$store.state.message
		},
		subscribed() {
			return this.$store.state.subscribed
		}
	},
	methods: {
		subscribe() {
			this.$store.commit({
					type: 'setMessage',
					payload: {
						alreadyExists: '',
						invalidEmail: '',
						invalidConsent: '',
						error: '' 
					}
			});

			const regex = new RegExp(/^\w+([\.\-\+]?\w*)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
			const validEmail = this.email.match(regex);

			if(validEmail && this.consent) {
				this.$store.dispatch(subscribe(this.email));
			}
			if(!validEmail) {
				this.$store.commit({
					type: 'setMessage',
					payload: {
						invalidEmail: this.translations.invalidEmail
					}
				});
			}
			if(!this.consent) {
				this.$store.commit({
					type: 'setMessage',
					payload: {
						invalidConsent: this.translations.invalidConsent
					}
				});
			}
		}
	},
	components: {
	}
})
